<template>
    <div>
        <section class="hero welcome  is-small">
            <div class="p-2 has-border-bottom-orange-2 ">
                <div class="">
                    <h1 class="title is-size-6">
                        <i class="fas fa-hourglass-half is-text-palete-orange-2"></i>
                        Pending Requests / To do List
                        <i @click.prevent="$parent.getMyModuleData('pending_requests')" class="fas fa-sync-alt is-pulled-right is-clickable"></i>
                    </h1>
                </div>
            </div>
        </section>
        <div class="table-wrapper has-mobile-cards custom_scrollbar-1 nf-pending-height card" style="">
            <table v-if="get_dashboard_pending_requests.length > 0" class="table is-striped dashboard-table  is-narrow is-fullwidth is-size-7">
                <thead>
                    <tr>
                        <th width="30px" draggable="false" class="is-sortable is-unselectable is-current-sort">
                            <!-- <div class="th-wrap"> </div> -->
                        </th>
                        <th draggable="false" class="is-sortable is-unselectable">
                            <div class="th-wrap">
                                <span class="is-relative">
                                {{$t("project_name") }}
                                </span>
                            </div>
                        </th>
                        <th width="180px" draggable="false" class="is-sortable is-unselectable">
                                {{$t("start_date") }} - {{$t("end_date") }}
                        </th>
                        <th>
                            Material
                        </th>
                        <th>
                            {{$t("comment") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(nf, index) in get_dashboard_pending_requests" :key="index" draggable="false" class="nf-pending-table">
                        <td v-if="nf.job_id != 0" class="has-text-centered">
                            <span v-if="nf.note_type == 'just-note'" :title=" $t('just_note') " style="" class="">
                                <i class="fas fa-comment-dots has-text-primary"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-amount-changed'" :title="$t('material_amount_changed_ititle')" style="" class="">
                                <i class="fas fa-check has-text-success"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-amount-change-request'" :title="$t('material_amount_change_request_ititle')" style="" class=" ">
                                <i class="fas fa-edit has-text-warning"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-deleted'" style="" :title="$t('material_deleted_ititle')" class="">
                                <i class="fas fa-trash has-text-danger"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-delete-request'" :title="$t('material_deleted_success_ititle')" style="" class="">
                                <i class="fas fa-trash has-text-warning"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-exchanged'" :title="$t('material_exchanged_ititle')" style="" class="">
                                <i class="fas fa-exchange-alt has-text-danger"></i>
                            </span>
                            <span v-if="nf.note_type == 'material-exchange-request'" :title="$t('material_exchange_request_ititle')" style="" class="">
                                <i class="fas fa-exchange-alt has-text-warning"></i>
                            </span>
                        </td>
						<td v-if="nf.job_id == 0" class="has-text-centered">
							<span :title="$t('note_type_' + nf.id_note_type)" class="">
								<i :class="{
										'has-text-light': nf.id_note_type == 0,
										'has-text-warning': nf.id_note_type == 1,
										'has-text-danger': nf.id_note_type == 2,
									}" class="fas fa-comment-dots"></i>
								<!-- <i  class="fas fa-comment-dots has-text-grey"></i> -->
							</span>
						</td>
						<td>
							{{nf.ej_number}}<br>
                        	{{nf.name}}
						</td>
                        <td>
                            <p class="m-0">
                                <span class="">{{nf.start_date|customdate}}</span>
                            </p>
                            <p class="m-0 has-text-right">
                                <span class="">{{nf.end_date|customdate}}</span>
                            </p>
                        </td>
                        <td>{{nf.material_name}}</td>
                        <td>
                            <p class="is-flex">
                                <span v-if="nf.created_by_name" style="font-size:9px;" >
                                    {{nf.created_by_name}} 
                                    <br>
                                </span>
                                <span class="has-text-grey-light ml-auto" style="font-size:9px;">
                                    {{nf.feed_created_at | local-datetime}}</span>
                            </p>
                            {{nf.content}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <small v-else class="ml-2 has-text-grey-light" >
                No data
            </small>
        </div>
        <!-- <footer v-if=" get_dashboard_pending_requests.length && get_dashboard_pending_requests.length != this.$parent.modules_data.my_pending_requests.length " class="card-footer border-none p-1">
            <a @click.prevent="toggle_limit()" href="#" class="card-footer-item p-0 has-text-white">{{$t("load_more")}}</a>
        </footer> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: 6,
            default_limit: 6
        }
    },
    computed: {
        get_dashboard_pending_requests(){ return this.$parent.modules_data.my_pending_requests.filter(a => {
                if(this.$parent.selected_project_id == a.project_id){
                    console.table(this.$parent.selected_project_id, a.project_id)
                    return a
                }
                else if(!this.$parent.selected_project_id) return a
            }) 
            .filter((obj) =>{
                if(this.$parent.tetris_search_text != ''){
                    return JSON.stringify(obj).toLowerCase().includes(this.$parent.tetris_search_text.toLowerCase())
                } else return obj
            })
        
         },
    },
    methods: {
        toggle_limit(){
            this.limit =  this.limit + this.default_limit
        },
    }

}
</script>

<style>

</style>
